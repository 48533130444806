import { render, staticRenderFns } from "./RsncQuestionComponent.vue?vue&type=template&id=48d6b458&scoped=true&"
import script from "./RsncQuestionComponent.vue?vue&type=script&lang=js&"
export * from "./RsncQuestionComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d6b458",
  null
  
)

export default component.exports