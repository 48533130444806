<template>
  <div>
    <div class="modal fade" id="nonConformityNew" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bolder">Add Non-Conformity</h5>
            <button type="button" class="close" ref="closeAddNonConformityNew" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row no-gutters mb-1">
              <label for="NonConformityQuestioner" class="font-weight-bold col-sm-2 col-form-label">Non-Conformity Question</label>
              <div class="col-sm-10">
                <button class="btn border btn-block text-left btn-sm"
                        data-target="#nonConformityLists"
                        data-toggle="modal"
                        type="button"
                        style="padding-top: .35rem; padding-bottom: .35rem;"
                >
                    <span style="font-size: 14px;">
                      {{this.code.hasOwnProperty('id') ? this.code.code.toUpperCase() + ' - ' +  this.code.main_question.toUpperCase() : '--- SELECT NON-CONFORMITY QUESTION ---' }}
                    </span>
                  <span style="float: right">
                      <font-awesome-icon icon="chevron-down"/>
                    </span>
                </button>
              </div>
            </div>
            <div class="form-group row no-gutters mb-1">
              <label for="NonConformityQuestioner" class="font-weight-bold col-sm-2 col-form-label">Non-Conformity Description</label>
              <div class="col-sm-10">
                <vue-editor  class="my-2"  v-model="fields.description" :editor-toolbar="toolbar"></vue-editor>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-primary btn-sm" @click="saveRsnc">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="nonConformityLists" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">SELECT NON-CONFORMITY QUESTION</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body overflow-auto " style="height: 50vh">
            <template v-for="(node,index) in rsncQuestionKeys">
              <rsnc-question-component :rsnc-code="code" :node="node" @setQuestion="setRsncQuestion"></rsnc-question-component>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PscActionCodeComponent from "@/components/modules/psc/deficiency/PscActionCodeComponent.vue";
import {mapActions, mapGetters} from "vuex";
import RsncQuestionComponent from "@/components/modules/rightship/RsncQuestionComponent.vue";
import {AlertService} from "@/services/AlertService";

export default {
  name: "NonConformityAdd",
  components: {RsncQuestionComponent, PscActionCodeComponent},
  data(){
    return{
      code:{},
      fields:{
        rs_id:this.$route.params.id,
        description:'',
        rsn_question_id:null,
      },
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ],
    }
  },
  methods:{
    ...mapActions(['saveRsNonConformity']),
    setRsncQuestion(value){
      this.code=value;
      this.fields.rsn_question_id=value.id
    },
   async saveRsnc(){
      if(await AlertService.confirmCreateAlert('Do you want to create new non conformity ? ')){
        const response = await this.saveRsNonConformity(this.fields);
        if(response) {
          AlertService.successAlert('Non conformity added successfully','Add Non Conformity')
          Object.assign(this.$data, this.$options.data.apply(this));
          $('#nonConformityNew').modal('hide')
          this.$emit('loadPage');
        }
      }
    }
  },
  computed:{
    ...mapGetters(['rsncQuestionKeys']),
  },

}
</script>

<style scoped>

</style>
