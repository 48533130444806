<script>
import NonConformityAdd from "@/components/modules/rightship/non-conformity/NonConformityAdd.vue";
import {mapActions, mapGetters} from "vuex";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import RsNonConformityMixin from "@/mixins/RsNonConformityMixin";
import {AlertService} from "@/services/AlertService";
export default {
  name: "RsNonConformity",
  components: {NonConformityAdd},
  mixins:[PaginationMixin,RsNonConformityMixin],
  data() {
    return {
      toEdit: false,
      filters:{},
      reportStatusInitial:[
        {
          id:1,
          name:'In Progress'
        },
        {
          id:2,
          name:'Ready to Close'
        },
        {
          id:3,
          name:'Closed'
        },
        {
          id:4,
          name:'Withdraw'
        },
      ]
    }
  },
  computed:{
    ...mapGetters(['rsNonConformities','pagination']),
  },
  methods: {
    ...mapActions(['getRsncQuestionKeys','getRsNonConformities','deleteNonConformity']),
    setTab(route='PscDeficiencyDetail'){
      this.$router.push({name:route,query:{tz:Date.now()}})
    },
    setPage(page = null){
      this.filters.page = page ?? 1
      this.getRsNonConformities(this.filters)
    },
    async confirmDeleteNonConformity(nonConformity){
      if (await AlertService.confirmDeleteAlert('Delete Non Conformity', 'Do you want to delete this non conformity ?', 'Yes Proceed')) {
        const response = await this.deleteNonConformity(nonConformity)
        if(response){
          AlertService.successAlert('Non Conformity Deleted Successfully', 'Delete Non Conformity')
          this.setPage(this.pagination.currentPage)
        }
      }
    }
  },
   created() {
     this.filters.rs_id=this.$route.params.id
     this.getRsncQuestionKeys();
     this.setPage(this.filters);
  },
  destroyed() {
    localStorage.removeItem('currentDeficiency')
  }
}
</script>

<template>
  <div>
    <div class="card p-3 shadow-sm">
      <div class="row justify-content-between">
        <div class="col-auto">
          <h5 class="e-text-red">
            Findings
          </h5>
        </div>
        <div class="col-auto">
          <button class="e-btn e-btn-green mb-2 px-4 e-text-white mx-2"
                  data-target="#nonConformityNew"
                  data-toggle="modal">
            <font-awesome-icon icon="plus" class="mr-2"/>
            Add Non-Conformity
          </button>
        </div>
      </div>
      <div class="row no-gutters justify-content-between mb-1">
        <div class="col-sm-auto pb-2 mr-2">
          <nav aria-label="...">
            <ul class="pagination text-smallest mb-0 pb-0">
              <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
                <button class="page-link" @click="setPage(1)">First</button>
              </li>

              <li v-for="page in paginationPages" :key="page" class="page-item"
                  :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
                <button class="page-link" @click="setPage(page)">{{ page }}</button>
              </li>

              <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
                <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <table class="table table-sm table-bordered text-center mb-0">
        <thead>
          <tr>
            <th width="1">#</th>
            <th style="width: 85px">Code</th>
            <th>List Of Findings</th>
            <th style="width: 85px">Status</th>
            <th style="width: 50px">Action</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(nonConformity,index) in rsNonConformities" :class="{'e-bg-blue-light-8':nonConformity.id==nonConformityId}">
          <td class="align-middle text-center">{{index + pagination.from}}</td>
          <td class="align-middle text-center">{{nonConformity.rsn_question.code}}</td>
          <td class="align-middle text-center">{{nonConformity.rsn_question.main_question}}</td>
          <td class="align-middle bg-056cb4">{{ nonConformity.report_status_id ? reportStatusInitial.filter(stat=>stat.id == nonConformity.report_status_id )[0].name : 'NEW' }}</td>
          <td>
            <div class="dropdown dropright my-0">
              <button
                id="actionDropdown"
                aria-expanded="false"
                aria-haspopup="true"
                class="btn btn-sm btn-secondary force-white-all"
                data-toggle="dropdown">
                <font-awesome-icon icon="cogs"/>
              </button>
              <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                <button class="dropdown-item text-secondary font-weight-bolder" @click="viewNonConformity(nonConformity)">
                  <font-awesome-icon icon="eye"/>
                  View
                </button>
                <button class="dropdown-item text-secondary font-weight-bolder text-danger"   @click="confirmDeleteNonConformity(nonConformity)">
                  <font-awesome-icon icon="trash" class="force-danger-all"/>
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="card p-3 shadow-sm mt-3" v-if="nonConformityId">
      <div class="row justify-content-between">
        <div class="col-auto">
          <h5 class="e-text-red">
            Detail of Findings
          </h5>
        </div>
      </div>

      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-item nav-link"  id="nav-Detail-tab" data-toggle="tab" role="tab"
             :class="{'active':$route.name==='NonConformityDetail'}"
             aria-controls="nav-Detail" aria-selected="true" @click="setTab('NonConformityDetail')">
            Detail
          </a>
          <a class="nav-item nav-link" id="nav-CorrectiveAction-tab" data-toggle="tab"
             role="tab"
             :class="{'active':$route.name==='NonConformityCorrectiveAction'}"
             @click="setTab('NonConformityCorrectiveAction')"
             aria-controls="nav-CorrectiveAction" aria-selected="false">
            Corrective Action
          </a>
          <a class="nav-item nav-link" id="nav-PreventativeMeasures-tab" data-toggle="tab" role="tab"
             :class="{'active':$route.name==='NonConformityPreventativeMeasure'}"
             @click="setTab('NonConformityPreventativeMeasure')"
             aria-controls="nav-PreventiveMeasures" aria-selected="false">
            Preventative Measures
          </a>
          <router-link class="nav-item nav-link" id="nav-CompanyInstruction-tab" data-toggle="tab"
                       :to="{name:'NonConformityCompanyInstruction',query:{non_conformity_id:this.nonConformityId}}"
                       @click="setTab('NonConformityCompanyInstruction')"
                       role="tab"
                       aria-controls="nav-CompanyInstruction" aria-selected="false">
            Company Instruction
          </router-link>
        </div>
      </nav>

      <div class="tab-content pt-2" id="nav-tabContent">
        <router-view></router-view>
      </div>

    </div>

    <NonConformityAdd @loadPage="setPage" />
  </div>
</template>

<style scoped>
.nav > .nav-link {
    padding: 8px 16px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: #435464;
    margin-bottom: -1px;
}

.nav > .active, .nav > .nav-link:hover {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
}

.nav-tabs {
    border-bottom: 2px solid #dee2e6;
}

table td {
    padding-bottom: .25rem;
}
</style>
