<template>
  <div>
    <template v-if="node.hasOwnProperty('children') && node.children.length">
      <div class="row align-items-center pl-4 py-0">
        <h6 class="h6">SECTION {{node.code}}: {{node.main_question}}</h6>
      </div>
      <template v-for="(node,index) in node.children">
        <RsncQuestionComponent :node="node" :rsnc-code="rsncCode" :dept="index" @setQuestion="(item)=>$emit('setQuestion',item)"></RsncQuestionComponent>
      </template>
    </template>
    <template v-else>
      <div class="form-check form-check-inline align-items-center d-flex pb-1 pl-3">
        <input class="form-check-input expand-25-percent mr-2" type="checkbox"
               :value="node.id"
               :disabled="!(rsncCode!==null && rsncCode.id != node.id)"
               @change="selectQuestion(node)"
               :checked="rsncCode.id==node.id"
        >
        <label class="form-check-label">{{node.code}} {{ node.main_question }}</label>
      </div>
    </template>
  </div>
</template>

<style scoped>

</style>

<script>
import PscActionCodeComponent from "@/components/modules/psc/deficiency/PscActionCodeComponent.vue";

export default {
  name: "RsncQuestionComponent",
  components: {PscActionCodeComponent},
  props: {
    node: Object,
    dept:0,
    rsncCode:Object
  },
  methods:{
    selectQuestion(value){
      this.$emit('setQuestion',value)
    }
  }
}
</script>
